<template>
    <div id="login">
        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field">
                    <div class="control has-icons-left">
                        <input
                            v-model="password"
                            id="password"
                            name="password"
                            class="input"
                            :placeholder="text[mode].enterPassword"
                            required>
                        <span class="icon is-small is-left">
                            <i class="fas fa-lock" />
                        </span>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal" />
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <button
                                @click="login"
                                class="button is-primary"
                                :disabled="!password || errorMsg !== ''">
                                {{ text[mode].login }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p
            v-if="errorMsg"
            class="help is-danger">
            {{ text[mode].incorrectPassword }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'Login',
    components: {},
    data: () => {
        return {
            text: {
                english: {
                    enterPassword: 'Enter Password',
                    login: 'Login',
                    incorrectPassword: 'Incorrect Password'
                },
                ukrainian: {
                    enterPassword: 'введіть пароль',
                    login: 'логін',
                    incorrectPassword: 'Неправильний пароль'
                }
            },
            password: '',
            errorMsg: ''
        };
    },
    watch: {
        password: function(newPassword, oldPassword) {
            if (this.errorMsg !== '') {
                if (newPassword !== oldPassword) {
                    this.errorMsg = '';
                }
            }
        }
    },
    computed: {
        mode() {
            return this.$store.state.mode;
        },
        appPassword() {
            return process.env.VUE_APP_PASSWORD;
        }
    },
    methods: {
        login() {
            if (this.password === this.appPassword) {
                localStorage.isAuthenticated = true;
                this.$router.push({name: 'Home'});
            } else {
                this.errorMsg = this.text[this.mode].incorrectPassword;
            }
        }
    }
};
</script>

