<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="558"
        height="558"
        viewBox="70 70 420 420">
        <defs>
            <filter id="glow">
                <!-- https://codepen.io/OpherV/pen/dRoQdN -->
                <fegaussianblur
                    class="blur"
                    result="coloredBlur"
                    stddeviation="4" />
                <femerge>
                    <femergenode in="coloredBlur" />
                    <femergenode in="coloredBlur" />
                    <femergenode in="coloredBlur" />
                    <femergenode in="SourceGraphic" />
                </femerge>
            </filter>
            <filter
                id="outter-ring"
                x="0"
                y="0"
                width="558.873"
                height="558.873"
                filterUnits="userSpaceOnUse">
                <feOffset
                    dy="-3"
                    input="SourceAlpha" />
                <feGaussianBlur
                    stdDeviation="3"
                    result="blur" />
                <feFlood flood-opacity="0.671" />
                <feComposite
                    operator="in"
                    in2="blur" />
            </filter>
            <filter
                id="outter-ring-2"
                x="0"
                y="0"
                width="558.873"
                height="558.873"
                filterUnits="userSpaceOnUse">
                <feOffset
                    dy="-3"
                    input="SourceAlpha" />
                <feGaussianBlur
                    stdDeviation="3"
                    result="blur-2" />
                <feFlood
                    flood-color="#fff"
                    flood-opacity="0.29"
                    result="color" />
                <feComposite
                    operator="out"
                    in="SourceGraphic"
                    in2="blur-2" />
                <feComposite
                    operator="in"
                    in="color" />
                <feComposite
                    operator="in"
                    in2="SourceGraphic" />
            </filter>
            <filter
                id="inner-ring"
                x="95.985"
                y="98.985"
                width="366.903"
                height="366.903"
                filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha" />
                <feGaussianBlur
                    stdDeviation="6"
                    result="blur-5" />
                <!-- <feFlood flood-color="#03bafc" /> -->
                <feComposite
                    operator="in"
                    in2="blur-5" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g
            id="Group_8"
            data-name="Group 8"
            transform="translate(363.371 -816.497) rotate(65)">
            <g
                id="outter_ring_group"
                data-name="Outter Ring Group"
                transform="translate(-72)">
                <g data-type="innerShadowGroup">
                    <g
                        class="cls-17"
                        transform="matrix(0.42, -0.91, 0.91, 0.42, 658.43, 674.39)">
                        <g
                            id="outter-ring-3"
                            data-name="outter-ring"
                            class="cls-1"
                            transform="translate(377.87 12) rotate(65)">
                            <circle
                                class="cls-10"
                                cx="203.5"
                                cy="203.5"
                                r="203.5" />
                            <circle
                                class="base-reset"
                                cx="203.5"
                                cy="203.5"
                                r="199.5" />
                        </g>
                    </g>
                    <g
                        class="cls-16"
                        transform="matrix(0.42, -0.91, 0.91, 0.42, 658.43, 674.39)">
                        <circle
                            id="outter-ring-4"
                            data-name="outter-ring"
                            class="cls-2"
                            cx="203.5"
                            cy="203.5"
                            r="203.5"
                            transform="translate(377.87 12) rotate(65)" />
                    </g>
                    <g
                        id="outter-ring-5"
                        data-name="outter-ring"
                        class="cls-1"
                        transform="translate(829 337)">
                        <circle
                            class="cls-10"
                            cx="203.5"
                            cy="203.5"
                            r="203.5" />
                        <circle
                            class="base-reset"
                            cx="203.5"
                            cy="203.5"
                            r="199.5" />
                    </g>
                </g>
            </g>
            <g
                class="glow-ring-group"
                transform="matrix(0.42, -0.91, 0.91, 0.42, 586.43, 674.39)">
                <g
                    id="Meter_Ring_Glow"
                    data-name="Meter Ring Glow"
                    class="meter-ring-glow-group"
                    transform="translate(339.66 116.99) rotate(65)">
                    <circle
                        class="base-reset meter-ring-glow"
                        cx="124.5"
                        cy="124.5"
                        r="120.5" />

                    <!-- could make a gradient between sections by doing something like this -->
                    <!-- <linearGradient id="linearColors2" x1="0" y1="0" x2="1" y2="1">
                        <stop offset="0%" stop-color="#e5ff00"></stop>
                        <stop offset="100%" stop-color="#00ff00"></stop>
                    </linearGradient>
                     <linearGradient id="linearColors3" x1="1" y1="0" x2="0" y2="1">
                        <stop offset="0%" stop-color="#ff0000"></stop>
                        <stop offset="100%" stop-color="#e5ff00"></stop>
                    </linearGradient>
                    <path
                        class="base-reset meter-arc-range is-yellow"
                        :class="{'is-on':threatLevel === 1}"
                        stroke="url(#linearColors2)"
                        d="M 22 234 A 150 150 349 0 1 -11 61" />
                    <path
                        class="base-reset meter-arc-range is-orange"
                        :class="{'is-on':threatLevel === 2}"
                        stroke="url(#linearColors3)"
                        d="M -11 61 A 150 150 349 0 1 143 -24" /> -->

                    <!-- <path
                        class="base-reset meter-arc-range is-yellow"
                        d="M 272 96 A 150 150 349 0 1 197 256" /> -->

                    <path
                        class="base-reset meter-arc-range is-green"
                        :class="{'is-on':threatLevel === 0 || threatLevel === 1}"
                        d="M 197 256 A 150 150 349 0 1 -11 61" />

                    <path
                        class="base-reset meter-arc-range is-red"
                        :class="{'is-on':threatLevel === 2 || threatLevel === 3}"
                        d="M -11 61 A 150 150 349 0 1 272 96" />

                    <!--
For arc calculations see http://xahlee.info/js/svg_circle_arc.html
Enter the values below, then add 72 to the "Start angle" to rotate each angle (72, 144, 216, 288)

Default values for starting bottom arc (hidden):
Ellipse center x y: 124.5, 124.5
Major/minor radius: 150, 150
Start angle and sweep: 0, 72
Rotation: 349

-->
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'ThreatMeterRing',
    props: ['threatLevel'],
    data: () => {
        return {
        };
    }
};
</script>

<style lang="scss">

.cls-1,
.base-reset,
.cls-5,
.meter-ring-glow-group,
.cls-8 {
    fill: none;
}

.cls-1 {
    stroke: rgba(0, 0, 0, 0.32);
}

.cls-1 {
    stroke-width: 8px;
}

.cls-2 {
    fill: #fff;
}

.cls-3,
.cls-4 {
    fill: rgba(255, 255, 255, 0.015);
}

.cls-3,
.cls-5 {
    stroke: rgba(6, 6, 8, 0.16);
}

.cls-3,
.cls-5,
.cls-8 {
    stroke-width: 3px;
}

.meter-ring-glow-group {
    /* stroke: #03bafc; */
}

.meter-ring-glow {
    stroke: rgb(3, 186, 252);
    filter: drop-shadow(0 0 8px rgba(3, 186, 252, 0.5));
    r: 120.5;
    stroke-width: 8px;
    animation: meterGrowAnimation 1.33s forwards, meterPulseAnimation 1s infinite alternate ease-in-out;
}

@keyframes meterGrowAnimation {
    100% {
        r: 150;
        stroke-width: 32px;
    }
}

@keyframes meterPulseAnimation {
    100% {
        stroke-width: 14px;
    }
}

.meter-arc-range {
    r: 120.5;
    stroke-width: 32px;
    opacity: 0.5;
    transition: all 0.2s;

    &.is-on {
        opacity: 1;
    }

    &.is-green {
        stroke: #00ff00;
        &.is-on {
            filter: drop-shadow(0 0 8px #00ff01);
            opacity: 1;
        }
    }
    &.is-yellow {
        stroke: #e5ff00;
        &.is-on {
            filter: drop-shadow(0 0 8px #e5ff00);
            opacity: 1;
        }
    }
    &.is-orange {
        stroke: #ffa500;
        &.is-on {
            filter: drop-shadow(0 0 8px #ffa500);
            opacity: 1;
        }
    }
    &.is-red {
        stroke: #ff0000;
        &.is-on {
            filter: drop-shadow(0 0 8px #ff0000);
            opacity: 1;
        }
    }
}

.cls-7 {
    fill: url(#linear-gradient);
}

.cls-8 {
    stroke: rgba(111, 111, 111, 0.09);
}

.cls-9 {
    fill: url(#radial-gradient);
}

.cls-10 {
    stroke: none;
}

.cls-12 {
    filter: url(#divot);
}

.glow-ring-group {
    /* filter: url(#glow); */
    filter: url(#inner-ring);
}

.cls-14 {
    filter: url(#knob-2);
}

.cls-15 {
    filter: url(#knob);
}

.cls-16 {
    filter: url(#outter-ring-2);
}

.cls-17 {
    filter: url(#outter-ring);
}
</style>
