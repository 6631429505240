<template>
    <div id="AudioSamples">
        <div class="samples content">
            <h1 class="title has-text-light">
                {{ text[mode].videoSamples }}
            </h1>
            <p>
                {{ text[mode].selectSample }}
            </p>

            <div class="tabs is-centered">
                <ul>
                    <li
                        @click="activeTab = 'DeepMedia AI'"
                        :class="{'is-active':activeTab === 'DeepMedia AI'}">
                        <a>DeepMedia AI</a>
                    </li>
                    <li
                        @click="activeTab = 'Respeecher'"
                        :class="{'is-active':activeTab === 'Respeecher'}">
                        <a>Respeecher</a>
                    </li>
                    <li
                        @click="activeTab = 'Unknown'"
                        :class="{'is-active':activeTab === 'Unknown'}">
                        <a>Unknown</a>
                    </li>
                    <li
                        @click="activeTab = 'MIT'"
                        :class="{'is-active':activeTab === 'MIT'}">
                        <a>MIT</a>
                    </li>
                </ul>
            </div>

            <div class="field is-grouped is-grouped-multiline is-grouped-centered">
                <div
                    v-for="sample in activeSamples"
                    :key="sample.name"
                    @click="loadAudio(sample)"
                    class="control is-clickable">
                    <div class="tags has-addons">
                        <span class="tag is-light">{{ sample.name }}</span>
                        <span
                            v-if="sample.generator"
                            class="tag"
                            :generator="sample.generator">{{ sample.generator }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import revaAPI from '../api/revaAPI.js';

export default {
    name: 'VideoSamples',
    components: {},
    data: () => {
        return {
            text: {
                english: {
                    videoSamples: 'Video Samples',
                    selectSample: "Select a sample below to see the video and view REVA's analysis."

                },
                ukrainian: {
                    videoSamples: 'Відео зразки',
                    selectSample: 'Виберіть зразок, щоб побачити відео та переглянути аналіз REVA'
                }
            },
            audioSamples: [
                {name: 'biden_un_russian.mp4', generator: 'DeepMedia AI'},
                {name: 'biden_draft.mp4', generator: 'Unknown'},
                {name: 'elon_musk.mp4', generator: 'Unknown'},
                {name: 'respeecher_skywalker.mp4', generator: 'Respeecher'},
                {name: 'deepfake_putin.mp4', generator: 'Unknown'},
                {name: 'fake_nixon.mp4', generator: 'MIT'}
            ],
            activeTab: 'DeepMedia AI'
        };
    },
    mounted() {
        if (!localStorage.isAuthenticated && this.$route.name !== 'Login') {
            return this.$router.push('/login');
        }
    },
    computed: {
        mode() {
            return this.$store.state.mode;
        },
        activeSamples() {
            const samples = [];

            for (let i = 0; i < this.audioSamples.length; i++) {
                if (this.audioSamples[i].generator === this.activeTab) {
                    samples.push(this.audioSamples[i]);
                }
            }
            return samples;
        },
        columnStyle() {
            if (this.activeSamples.length <= 3) {
                return 'column is-12-desktop is-12-tablet';
            } else if (this.activeSamples.length <= 6) {
                return 'column is-7-desktop is-9-tablet';
            } else {
                return 'column is-6-desktop is-6-tablet';
            }
        }
    },
    methods: {
        // Go to the demo screen and playback the selected audio sample
        loadAudio: function(sample) {
            const filename = sample.name;

            // using a sample file from the list
            revaAPI.getFileUrl(filename, (response) => {
                if (response.data) {
                    const file = {
                        title: filename,
                        display: filename,
                        source: 'fileName',
                        isVideo: true,
                        authenticateVoice: false,
                        filename: filename,
                        url: response.data.audio_url
                    };

                    localStorage.currentAudio = JSON.stringify(file);
                    localStorage.sampleIsVideo = true;
                    localStorage.isMicInput = false;

                    // NOTE: enable to go to the next page with the clicked file
                    this.$router.push({name: 'Demo'});
                } else {
                    console.log('File ' + filename + ' not found on server');
                }
            });
        }
    }
};
</script>
