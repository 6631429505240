<template>
    <modal
        :show="show"
        @close="close">
        <template #headerText>
            {{ alert.title }}
        </template>

        <template #bodyContent>
            <div class="content">
                <p>
                    <strong>{{ alert.description }}</strong>
                </p>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/_templates/modal';

export default {
    name: 'ModalAlert',
    components: {
        Modal
    },
    data: () => {
        return {
            text: {
                english: {
                    alert: 'Alert',
                    wentWrong: 'Something went wrong'
                },
                ukrainian: {
                    alert: 'Сповіщення',
                    wentWrong: 'Щось пішло не так'
                }
            }
        };
    },
    computed: {
        mode() {
            return this.$store.state.mode;
        },
        show: function() {
            return this.$store.getters.isVisible('alert');
        },
        alert: function() {
            var alert = this.$store.state.modalAlertContent;
            if (!alert.title) alert.title = this.text[this.mode].alert;
            if (!alert.description) alert.description = this.text[this.mode].wentWrong;
            return alert;
        }
    },
    methods: {
        close: function() {
            this.$store.commit('hideModal', 'alert');
        }
    }
};
</script>
