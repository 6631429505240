<template>
    <transition name="modal">
        <div
            class="modal">
            <!-- This is the root layout. Other components build off of this and supply content -->
            <!-- <Slots> contain default content which can be overwritten in each instance of this template -->
            <!-- TODO: Trap and focus TAB key navigation while modal isActive -->
            <div
                class="modal-background"
                @click="close" />
            <div
                class="modal-card"
                :class="{'is-large': largeModal}"
                role="dialog"
                aria-labelledby="modalTitle"
                aria-describedby="modalDescription">
                <header
                    id="modalTitle"
                    class="modal-card-head">
                    <p class="modal-card-title">
                        <slot name="headerText">
                            Modal header text
                        </slot>
                    </p>
                    <button
                        class="delete"
                        aria-label="close"
                        @click.stop="close" />
                </header>
                <section
                    id="modalDescription"
                    class="modal-card-body">
                    <slot name="bodyContent">
                        <p>Modal content here. Can contain any HTML elements.</p>
                    </slot>
                </section>
                <footer class="modal-card-foot">
                    <slot name="extraFooterOption">
                        <!-- Optional footer stuff -->
                    </slot>
                    <div class="buttons is-right">
                        <button
                            class="button is-light is-outlined"
                            @click="close">
                            <slot name="closeButtonLabel">
                                Close
                            </slot>
                        </button>
                        <slot name="actionButton">
                            <!-- <button
                                class="button"
                                @click="close"
                            >Do it!</button> -->
                        </slot>
                    </div>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        largeModal: Boolean
    },
    mounted: function() {
        document.addEventListener('keyup', (e) => {
            if (e.keyCode === 27) {
                this.close();
            }
        });
    },
    methods: {
        close: function() {
            this.$emit('close');
        }
    }
};
</script>

<style>

.modal-card.is-large {
    min-width: 70vw;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}
.modal-leave-active {
    opacity: 0;
}
.modal-enter .modal-card,
.modal-leave-active .modal-card {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
