<template>
    <div id="demo">
        <div class="columns">
            <div class="column">
                <div class="demo-header is-flex is-justify-content-center is-align-content-center">
                    <!-- Play/pause or recording controls -->
                    <div class="mr-3">
                        <!-- If just a file, allow play/pause -->
                        <label class="play-button">
                            <input
                                type="checkbox"
                                v-model="isPaused"><span />
                        </label>
                    </div>

                    <div
                        v-if="isPaused"
                        class="filename">
                        <strong>{{ audioLabel }}</strong> / {{ text[mode].paused }}
                    </div>
                    <div
                        v-else
                        class="filename">
                        <strong>{{ audioLabel }}</strong> / {{ text[mode].analysis }}
                    </div>
                </div>

                <div
                    id="knob"
                    class="knob-rotate"
                    :class="{ 'is-analyzing': false }">
                    <!-- Use inline vue svg instead of embedded svg image so it can be manipulated -->
                    <meter-ring :threat-level="threatLevel" />

                    <!-- only show if recording audio -->
                    <img
                        class="knob-rotate"
                        :class="{ 'meter-spin-mode': false }"
                        :style="{
                            transform: 'rotate(' + getKnobAngle() + 'deg)',
                            filter: 'hue-rotate(' + colorAngle + 'deg',
                        }"
                        src="@/assets/meter-needle.svg">
                    <div
                        class="meter-status-message"
                        v-html="meterStatusMessage" />
                </div>

                <!-- This is where we display the list of warnings -->
                <div class="demo-footer">
                    <ul class="threats-found has-text-centered">
                        <li
                            v-if="!tamperingWarning && !gotFinalResult"
                            :class="{
                                'no-warnings': true,
                            }">
                            {{ text[mode].analyzing }}
                        </li>
                        <li
                            v-else-if="!tamperingWarning && gotFinalResult"
                            :class="{
                                'no-warnings': true,
                            }">
                            {{ text[mode].noGeneratedAudioDetected }}
                        </li>
                        <li
                            v-else
                            :class="{
                                'threat-level': tamperingWarning,
                                'no-warnings': !tamperingWarning,
                            }">
                            {{ text[mode].generatedAudioDetected }}
                        </li>
                    </ul>
                </div>

                <waveSurfer
                    id="demo-wave"
                    ref="wavesurferAudio"
                    :file="audio.url"
                    @ready="wavesurferReady" />
            </div>
            <div
                id="video-column"
                class="column">
                <video
                    id="video"
                    :src="audio.url"
                    ref="video">
                    Sorry, your browser doesn't support embedded videos
                </video>

                <waveSurfer
                    ref="wavesurferVideo"
                    :file="audio.url"
                    @ready="wavesurferReady" />
            </div>
        </div>
    </div>
</template>

<script>
import meterRing from '../assets/meter-ring.vue';
import {knobMixin} from '../mixins/knobMixin.js';
import waveSurfer from '@/components/waveSurfer.vue';


export default {
    name: 'MainDemo',
    mixins: [knobMixin],
    components: {
        meterRing,
        waveSurfer
    },
    data: () => {
        return {
            text: {
                english: {
                    generatedAudioDetected: 'Generated Audio Detected',
                    noGeneratedAudioDetected: 'No Generated Audio Detected',
                    paused: 'Paused',
                    analysis: 'Analysis',
                    analyzing: 'Analyzing...',
                    externalLink: 'External Link',
                    uploadedFile: 'Uploaded File'
                },
                ukrainian: {
                    generatedAudioDetected: 'Виявлено синтезований аудіо',
                    noGeneratedAudioDetected: 'Синтезований аудіо не виявлено',
                    paused: 'призупинено',
                    analysis: 'аналізу',
                    analyzing: 'Аналізуючи...',
                    externalLink: 'Зовнішнє посилання',
                    uploadedFile: 'Завантажено'
                }
            },
            messages: [],
            knobAngle: -70,
            websocketConnection: null,
            gotFinalResult: false,
            tamperingWarning: false,
            analysisInterval: null,
            isPaused: false,
            secondsSinceLastAnalysisRequest: 0,
            secondsBetweenAnalysisRequests: 3,
            audioFile: null
        };
    },
    computed: {
        mode() {
            return this.$store.state.mode;
        },
        audio() {
            return JSON.parse(localStorage.currentAudio);
        },
        isVideo() {
            return localStorage.sampleIsVideo;
        },
        meterStatusMessage() {
            // Break long messages with <br/>
            if (this.threatLevel === 1) return '';
            return '';
        },
        websocketURL() {
            const wsURL = process.env.VUE_APP_WS_URL;
            const apiKey = process.env.VUE_APP_REVA_API_KEY;
            const queryParams = '?token=' + apiKey;
            return wsURL + 'files/' + this.audio.title + '/analyze' + queryParams;
        },
        audioLabel() {
            if (this.audio.source === 'link') {
                return this.text[this.mode].externalLink;
            } else if (this.audio.source === 'upload') {
                return this.text[this.mode].uploadedFile;
            }
            return this.audio.display;
        },
        video() {
            return this.$refs.video;
        },
        wavesurfer() {
            if (this.isVideo === 'true') {
                return this.$refs.wavesurferVideo;
            } else {
                return this.$refs.wavesurferAudio;
            }
        }
    },
    watch: {
        isPaused: function(paused) {
            if (paused) {
                if (this.isVideo === 'true') {
                    this.video.pause();
                } else {
                    this.audioFile.pause();
                }
                this.wavesurfer.pause();
            } else {
                if (this.isVideo === 'true') {
                    this.video.play();
                } else {
                    this.audioFile.play();
                }
                this.wavesurfer.play();
            }
            this.wiggleNeedle = !this.wiggleNeedle;
        }
    },
    mounted: function() {
        if (!localStorage.isAuthenticated && this.$route.name !== 'Login') {
            return this.$router.push('/login');
        }

        this.$nextTick(function() {
            window.setInterval(() => {
                this.setRandomWiggle();
            }, 200);
        });

        // if audio not set go home
        if (!this.audio.title) this.$router.push('/');

        // else run analysis
        this.beginAnalysis(this.audio.url);
    },
    methods: {
        wavesurferReady: function() {
            // wavesurfer player is ready, so play the sample now
            if (this.isVideo === 'true') {
                this.video.play();

                this.video.addEventListener('ended', () => {
                    this.isPaused = true;
                });
            } else {
                this.audioFile.play();

                this.audioFile.addEventListener('ended', () => {
                    this.isPaused = true;
                });
            }
        },
        establishWebsocketConnection: function() {
            this.websocketConnection = new WebSocket(this.websocketURL);

            this.websocketConnection.onmessage = (e) => {
                const analysisResults = JSON.parse(e.data);
                console.log('Analysis Results:', analysisResults);

                const resultsType = analysisResults.response_type; // only get this when analyzing file uploads (not mic input)
                const tamperingWarning = analysisResults.tampering_detected;

                // only add message once
                if (tamperingWarning && !this.tamperingWarning) {
                    this.tamperingWarning = true;
                    this.updateWarning(this.text[this.mode].generatedAudioDetected);
                }

                // handling final message
                if (resultsType === 'final') {
                    this.displayFinalResult();
                }
            };
        },
        updateWarning: function(warningText) {
            this.increaseThreatLevel();
            this.playSound('sfx_unrecognized1.mp3');
        },
        displayFinalResult: function() {
            if (!this.tamperingWarning) {
                this.gotFinalResult = true;
                this.threatLevel = 0;
                this.knobAngle = -70;
                this.colorAngle = 288;
                this.playSound('sfx_trusted1.mp3');
            }
        },

        // Begin analysis for input file
        beginAnalysis: function(audioUrl) {
            this.establishWebsocketConnection();
            if (this.isVideo === 'true') {
                const audioWave = document.getElementById('demo-wave');
                audioWave.remove();

                // this event fires when the video playback has ended
                this.video.addEventListener('ended', () => {
                    clearInterval(this.analysisInterval);

                    // request final message from server
                    const req = {requestType: 'final'};
                    this.websocketConnection.send(JSON.stringify(req));
                });
            } else {
                // NOTE: weird wavesurfer workaround
                const videoWave = document.getElementById('video-column');
                videoWave.remove();

                this.audioFile = new Audio(audioUrl);
                const startPlayPromise = this.audioFile.play();
                if (startPlayPromise !== undefined) {
                    startPlayPromise.then(() => {
                        // audio playback started fine
                    }).catch(error => {
                        // audio isn't playing (likely due to autoplay restrictions)
                        // set to paused and let the user start the audio
                        console.log('audio not playing due to:', error.name);
                        this.isPaused = true;
                    });
                }

                // this event fires when the audio playback has ended
                this.audioFile.addEventListener('ended', () => {
                    clearInterval(this.analysisInterval);

                    // request final message from server
                    const req = {requestType: 'final'};
                    this.websocketConnection.send(JSON.stringify(req));
                });
            }

            // The following controls how we request analysis results for the audio file we are playing
            this.websocketConnection.onopen = () => {
                const delay = 1000; // how often to execute the following code

                this.analysisInterval = setInterval(() => {
                    // only update timer if audio is not currently paused
                    if (!this.isPaused) {
                        this.secondsSinceLastAnalysisRequest++;

                        if (this.secondsSinceLastAnalysisRequest >= this.secondsBetweenAnalysisRequests) {
                            const req = {requestType: 'chunk'};
                            this.websocketConnection.send(JSON.stringify(req));
                            this.secondsSinceLastAnalysisRequest = 0;
                        }
                    }
                }, delay);
            };
        },
        playSound(filename) {
            const soundEffect = new Audio(require('@/assets/' + filename));
            soundEffect.volume = 0.4;
            soundEffect.play();
        },
        showModalAlert(id, content) {
            if (id !== '') {
                this.$store.commit('setModalAlertContent', content);
                this.$store.commit('changeCurrentModal', id);
                this.wiggleNeedle = false;
                return this.activeModal === id;
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.websocketConnection) {
            this.websocketConnection.close();
        }
        if (this.isVideo === 'true') {
            if (this.video) {
                this.video.pause();
            }
        } else {
            if (this.audioFile) {
                this.audioFile.pause();
            }
        }
        clearInterval(this.analysisInterval);
        next();
    }
};
</script>
