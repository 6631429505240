var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"demo"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"demo-header is-flex is-justify-content-center is-align-content-center"},[_c('div',{staticClass:"mr-3"},[_c('label',{staticClass:"play-button"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isPaused),expression:"isPaused"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isPaused)?_vm._i(_vm.isPaused,null)>-1:(_vm.isPaused)},on:{"change":function($event){var $$a=_vm.isPaused,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isPaused=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isPaused=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isPaused=$$c}}}}),_c('span')])]),(_vm.isPaused)?_c('div',{staticClass:"filename"},[_c('strong',[_vm._v(_vm._s(_vm.audioLabel))]),_vm._v(" / "+_vm._s(_vm.text[_vm.mode].paused)+" ")]):_c('div',{staticClass:"filename"},[_c('strong',[_vm._v(_vm._s(_vm.audioLabel))]),_vm._v(" / "+_vm._s(_vm.text[_vm.mode].analysis)+" ")])]),_c('div',{staticClass:"knob-rotate",class:{ 'is-analyzing': false },attrs:{"id":"knob"}},[_c('meter-ring',{attrs:{"threat-level":_vm.threatLevel}}),_c('img',{staticClass:"knob-rotate",class:{ 'meter-spin-mode': false },style:({
                        transform: 'rotate(' + _vm.getKnobAngle() + 'deg)',
                        filter: 'hue-rotate(' + _vm.colorAngle + 'deg',
                    }),attrs:{"src":require("@/assets/meter-needle.svg")}}),_c('div',{staticClass:"meter-status-message",domProps:{"innerHTML":_vm._s(_vm.meterStatusMessage)}})],1),_c('div',{staticClass:"demo-footer"},[_c('ul',{staticClass:"threats-found has-text-centered"},[(!_vm.tamperingWarning && !_vm.gotFinalResult)?_c('li',{class:{
                            'no-warnings': true,
                        }},[_vm._v(" "+_vm._s(_vm.text[_vm.mode].analyzing)+" ")]):(!_vm.tamperingWarning && _vm.gotFinalResult)?_c('li',{class:{
                            'no-warnings': true,
                        }},[_vm._v(" "+_vm._s(_vm.text[_vm.mode].noGeneratedAudioDetected)+" ")]):_c('li',{class:{
                            'threat-level': _vm.tamperingWarning,
                            'no-warnings': !_vm.tamperingWarning,
                        }},[_vm._v(" "+_vm._s(_vm.text[_vm.mode].generatedAudioDetected)+" ")])])]),_c('waveSurfer',{ref:"wavesurferAudio",attrs:{"id":"demo-wave","file":_vm.audio.url},on:{"ready":_vm.wavesurferReady}})],1),_c('div',{staticClass:"column",attrs:{"id":"video-column"}},[_c('video',{ref:"video",attrs:{"id":"video","src":_vm.audio.url}},[_vm._v(" Sorry, your browser doesn't support embedded videos ")]),_c('waveSurfer',{ref:"wavesurferVideo",attrs:{"file":_vm.audio.url},on:{"ready":_vm.wavesurferReady}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }