<template>
    <div
        id="app"
        :class="{'tall-page':showBackBtn}">
        <div class="language-dropdown">
            <div class="dropdown is-right is-hoverable">
                <div class="dropdown-trigger">
                    <a
                        href="#"
                        class="dropdown-item">
                        <span
                            v-if="mode=='english'"
                            @click="setMode('english')"><i class="fas fa-globe mr-2" />English</span>
                        <span
                            v-if="mode=='ukrainian'"
                            @click="setMode('ukrainian')"><i class="fas fa-globe mr-2" />Ukrainian</span>
                        <i class="fas fa-angle-down ml-3" />
                    </a>
                </div>
                <div
                    class="dropdown-menu"
                    id="dropdown-menu"
                    role="menu">
                    <div class="dropdown-content">
                        <div
                            v-if="mode=='english'"
                            class="dropdown-item is-clickable is-flex is-flex-wrap-nowrap is-align-items-center"
                            @click="setMode('ukrainian')">
                            <img
                                class="mr-3"
                                src="./assets/ukraine.png"
                                style="width: 15px"><div>Ukrainian</div>
                        </div>
                        <div
                            v-if="mode=='ukrainian'"
                            class="dropdown-item is-clickable is-flex is-flex-wrap-nowrap is-align-items-center"
                            @click="setMode('english')">
                            <img
                                class="mr-3"
                                src="./assets/usa.png"
                                style="width: 15px"><div>English</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="nav">
            <a
                @click="goBack"
                id="backBtn"
                :class="{'hide-btn':!showBackBtn}">
                <i class="fa-fw fas fa-arrow-left" />
            </a>

            <router-link
                :to="{name: 'Home'}">
                <img
                    class="logo"
                    src="@/assets/logo.svg"
                    alt="Reva logo">
            </router-link>
        </div>

        <div id="main-content">
            <router-view
                :key="$route.fullPath" />
        </div>

        <div id="about">
            <!-- <button
                id="info-button"
                class="button"
                @click="showModal('aboutReva')">
                <span class="icon">
                    <i class="fa-fw fas fa-info" />
                </span>
            </button> -->
            <!-- <label for="info-button">About</label> | -->
            <div class="buttons">
                <button
                    v-if="mode == 'English'"
                    class="button is-raised"
                    @click="showModal('aboutReva')">
                    {{ text[mode].about }}
                </button>
                <a
                    class="button is-raised"
                    href="mailto:benjamin.bell@eduworks.com?subject=I%20would%20like%20to%20know%20more%20about%20REVA"
                    target="_blank">{{ text[mode].contactUs }}</a>
            </div>
        </div>

        <modal-about-reva
            id="modalAboutReva"
            :show="isVisible('aboutReva')"
            :class="{ 'is-active':isVisible('aboutReva') }"
            @close="closeModal('aboutReva')" />
        <modal-alert
            id="modalAlert"
            :show="isVisible('alert')"
            :class="{ 'is-active':isVisible('alert') }"
            @close="closeModal('alert')" />
    </div>
</template>

<script>
import modalAboutReva from '@/components/modalAboutReva.vue';
import modalAlert from '@/components/modalAlert.vue';

export default {
    name: 'App',
    data: () => {
        return {
            text: {
                english: {
                    about: 'About',
                    contactUs: 'Contact Us'
                },
                ukrainian: {
                    about: 'Про нас',
                    contactUs: "Зв'яжіться з нами"
                }
            }
        };
    },
    components: {
        modalAboutReva,
        modalAlert
    },
    computed: {
        mode() {
            return this.$store.state.mode;
        },
        showBackBtn() {
            if (this.$route.name === 'Home' || this.$route.name === 'Login') {
                return false;
            }
            return true;
        },
        languageMode: {
            get() {
                return this.$store.getters.mode;
            },
            set(value) {
                this.$store.commit('setMode', value);
            }
        }
    },
    mounted() {
        this.setMode('english');
        // send to login page if trying to go somewhere else without being authenticated
        if (!localStorage.isAuthenticated && this.$route.name !== 'Login') {
            return this.$router.push('/login');
        }
    },
    methods: {
        isVisible: function(id) {
            if (id !== '') {
                return this.$store.getters.isVisible(id);
            } else {
                return false;
            }
        },
        setMode(value) {
            this.languageMode = value;
        },
        goBack: function() {
            // return this.$router.go(-1);
            return this.$router.push('/');
        },
        showModal: function(id) {
            if (id !== '') {
                this.$store.commit('changeCurrentModal', id);
                return this.activeModal === id;
            }
        },
        closeModal: function(id) {
            if (id !== '') {
                // this.$store.commit('clearModals');
                this.$store.commit('hideModal', id);
                return false;
            }
        }
    }
};
</script>
<style>
.language-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: .5rem;
}

a.dropdown-item {
    padding: 0rem !important;
}

a.dropdown-item:hover {
    background-color: transparent !important;
}

a.dropdown-item:hover .fa-globe {
    transform: scale(1.1);
}

.dropdown-menu {
    min-width: 7.5rem !important;
}

.dropdown-content {
    background-color: rgba(255,255,255,.04) !important;
    box-shadow: none !important;
    padding: 0rem !important;
}
</style>
