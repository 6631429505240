<template>
    <modal
        :show="show"
        @close="close">
        <template #headerText>
            Privacy Information
        </template>

        <template #bodyContent>
            <div class="content">
                <p>
                    <strong>To create a voice clone REVA uses the Eleven Labs API. REVA sends your
                        voice to the Eleven Labs API service and it is stored on their servers. You may view
                        the <a
                            href="https://beta.elevenlabs.io/privacy"
                            target="_blank">full Eleven Labs privacy notice</a>
                        or <a
                            href="https://beta.elevenlabs.io/terms"
                            target="_blank">the Eleven Labs Terms of Service</a>.</strong>
                </p>
            </div>
        </template>

        <template #extraFooterOption>
            <div class="label">
                For more information <a
                    href="mailto:benjamin.bell@eduworks.com?subject=I%20would%20like%20to%20know%20more%20about%20REVA"
                    target="_blank">contact us</a>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/_templates/modal';

export default {
    name: 'ModalPrivacyInfo',
    components: {
        Modal
    },
    data: () => {
        return {
        };
    },
    computed: {
        show: function() {
            return this.$store.getters.isVisible('privacyInfo');
        }
    },
    methods: {
        close: function() {
            this.$store.commit('hideModal', 'privacyInfo');
        }
    }
};
</script>
