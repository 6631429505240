<template>
    <div>
        <div
            v-if="!uploadSuccess"
            id="uploadFile"
            class="has-text-centered">
            <div
                class="dropzone-area"
                :class="[dragOver ? 'dropzone-over' : '']"
                drag-over="handleDragOver"
                @dragenter="dragOver=true"
                @dragleave="dragOver=false">
                <div v-if="!isLoading">
                    <div class="dropzone-text">
                        <h1 class="title has-text-light">
                            {{ text[mode].dropFileHere }}
                        </h1>
                        <h2 class="subtitle">
                            {{ text[mode].zipFilesInstructions }}
                        </h2>
                        <h2 class="subtitle">
                            {{ text[mode].orClickToBrowse }}
                        </h2>
                    </div>
                    <input
                        type="file"
                        ref="file"
                        accept=".mp3,.wav,.mp4,.opus,.m4a,.ogg,.flac,.mkv,.webm,.zip,.csv"
                        @change="checkAudioFile">
                </div>

                <div
                    v-else
                    class="dropzone-text">
                    <LoadingWave />
                    <h2
                        v-if="!isBulkUpload"
                        class="subtitle">
                        {{ text[mode].loading }}
                    </h2>
                    <h2
                        v-if="fileInProgress && isBulkUpload"
                        class="subtitle">
                        Processing file: {{ fileInProgress }}
                    </h2>
                    <h2
                        v-else-if="!fileInProgress && isBulkUpload"
                        class="subtitle">
                        {{ text[mode].uploadingFile }}
                    </h2>
                </div>
            </div>
        </div>
        <div
            v-else
            id="bulkResults"
            class="has-text-centered">
            <h1 class="title is-4 has-text-light">
                {{ text[mode].analysisResults }}
            </h1>

            <div id="log-window">
                <table>
                    <tr>
                        <th>{{ text[mode].fileName }}</th>
                        <th>{{ text[mode].tamperingDetected }}</th>
                        <th>{{ text[mode].analysisTime }}</th>
                    </tr>
                    <tr
                        v-for="res in analysisResults"
                        :key="res.filename">
                        <td>{{ res.filename }}</td>
                        <td>{{ trueFalseTranslation(res.tampering_results.tampering_detected) }}</td>
                        <td>{{ res.tampering_results.analysis_time }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
import revaAPI from '../api/revaAPI.js';
import LoadingWave from '../components/loadingWave.vue';

export default {
    name: 'UploadFile',
    components: {LoadingWave},
    data: () => {
        return {
            text: {
                english: {
                    dropFileHere: 'Drop an audio or video file here',
                    zipFilesInstructions: 'Zip files containing media or csv files with links to media are also valid',
                    orClickToBrowse: 'or click to browse',
                    fileTooLarge: 'Your file is too large',
                    uploadLimit: 'The upload limit is 10MB',
                    uploadFailed: 'File upload failed...',
                    loading: 'Loading...',
                    uploadingFile: 'Uploading File',
                    fileName: 'Filename',
                    analysisTime: 'Analysis Time',
                    tamperingDetected: 'Tampering Detected',
                    analysisResults: 'Analysis Results'
                },
                ukrainian: {
                    dropFileHere: 'Перетягніть сюди аудіо- чи відеофайл',
                    zipFilesInstructions: 'Zip-файли, що містять медіафайли або файли cvs із посиланнями на медіафайли, також дозво́лені',
                    orClickToBrowse: 'або натисніть, щоб переглянути далі',
                    fileTooLarge: 'Ваш файл занадто великий',
                    uploadLimit: 'Ліміт завантаження - 10 МБ',
                    uploadFailed: 'Помилка завантаження файлу',
                    loading: 'завантаження...',
                    uploadingFile: 'завантаження файлу',
                    fileName: 'назва файлу',
                    analysisTime: 'тривалість аналізу',
                    tamperingDetected: 'виявлено втручання',
                    analysisResults: 'результати аналізу'
                }
            },
            dragOver: false,
            audioFile: null,
            isLoading: false,
            bulkSupportedFileTypes: [
                'zip',
                'csv',
                'txt'
            ],
            supportedFileTypes: ['mp3', 'wav', 'mp4', 'opus', 'm4a', 'ogg', 'flac', 'mkv', 'webm'],
            analysisResults: [],
            uploadSuccess: false,
            totalAnalysisTime: 0,
            zipFilename: '',
            websocketConnection: null,
            fileInProgress: null,
            filetype: null,
            isBulkUpload: false
        };
    },
    mounted() {
        if (!localStorage.isAuthenticated && this.$route.name !== 'Login') {
            return this.$router.push('/login');
        }
    },
    computed: {
        mode() {
            return this.$store.state.mode;
        },
        websocketURL() {
            const wsURL = process.env.VUE_APP_WS_URL;
            const apiKey = process.env.VUE_APP_REVA_API_KEY;
            const queryParams = '?token=' + apiKey;

            let type = '';

            if (this.filetype === 'zip') {
                type = 'uploads/bulk/';
            } else {
                type = 'links/bulk/';
            }

            const url = wsURL + type + this.zipFilename + '/analyze' + queryParams;
            return url;
        }
    },
    methods: {
        trueFalseTranslation(x) {
            if (x === true && this.mode === 'english') {
                return true;
            }
            if (x === false && this.mode === 'english') {
                return false;
            }
            if (x === true && this.mode === 'ukrainian') {
                return 'істина';
            }
            if (x === false && this.mode === 'ukrainian') {
                return 'хиба';
            }
        },
        establishWebsocketConnection: function() {
            this.websocketConnection = new WebSocket(this.websocketURL);

            this.websocketConnection.onmessage = (e) => {
                const analysisResults = JSON.parse(e.data);
                console.log('Analysis Results:', analysisResults);

                if (analysisResults.total_analysis_time !== undefined) {
                    this.displayResults(analysisResults);
                } else {
                    this.fileInProgress = analysisResults.filename;
                }
            };
        },
        displayResults(data) {
            this.isLoading = false;
            this.uploadSuccess = true;
            this.analysisResults = data.results;
            this.totalAnalysisTime = data.total_analysis_time;
        },
        getBulkSupportedFileType(filename) {
            const ext = filename.split('.').pop();
            if (this.bulkSupportedFileTypes.includes(ext)) {
                return ext;
            }
            return null;
        },
        checkAudioFile() {
            this.audioFile = [...this.$refs.file.files][0];
            const filetype = this.getBulkSupportedFileType(this.audioFile.name);

            if (filetype !== null) {
                this.isBulkUpload = true;

                if (this.audioFile) {
                    this.isLoading = true;
                    this.filetype = filetype;

                    revaAPI.uploadFile(this.audioFile, (response) => {
                        if (response.data) {
                            this.zipFilename = response.data.filename;
                            this.establishWebsocketConnection();
                        }
                    });
                }
            } else if (this.isFileTypeSupported(this.audioFile.name)) {
                if (this.audioFile) {
                    const maxAudioFileSize = 20 * 1024 * 1024; // 20 mb

                    if (this.audioFile.size > maxAudioFileSize) {
                        this.showModalAlert('alert',
                            {
                                title: this.text[this.mode].fileTooLarge,
                                description: this.text[this.mode].uploadLimit
                            });
                    } else {
                        this.isLoading = true;

                        revaAPI.uploadFile(this.audioFile, (response) => {
                            if (response.data) {
                                const audio = {
                                    title: response.data.filename,
                                    display: '',
                                    source: 'upload',
                                    url: response.data.audio_url,
                                    filename: this.audioFile.name
                                };

                                const hasVideo = response.data.is_video;
                                localStorage.currentAudio = JSON.stringify(audio);
                                localStorage.sampleIsVideo = hasVideo;
                                localStorage.isMicInput = false;

                                this.$router.push({name: 'Demo'});
                            } else {
                                console.log('File upload failed...');
                            }
                        });
                    }
                }
            } else {
                alert('Please enter an MP3, WAV, MP4, OPUS, M4A, OGG, WEBM, MKV, FLAC, zip, or csv file');
                this.audioFile = null;
            }
        },
        isFileTypeSupported(filename) {
            const ext = filename.split('.').pop();
            if (this.supportedFileTypes.includes(ext)) {
                return true;
            }
            return false;
        },
        showModalAlert(id, content) {
            if (id !== '') {
                this.$store.commit('setModalAlertContent', content);
                this.$store.commit('changeCurrentModal', id);
                return this.activeModal === id;
            }
        },
        beforeRouteLeave(to, from, next) {
            if (this.websocketConnection) {
                this.websocketConnection.close();
            }
            next();
        }
    }
};
</script>

