export const knobMixin = {
    data: () => {
        return {
            knobAngle: 0,
            wiggleNeedle: true,
            wiggleOffset: 0,
            colorAngle: 235,
            threatLevel: 1
        };
    },
    methods: {
        knobTurnTo: function(e) {
            const knobXY = this.getCenterPoint(document.getElementById('knob'));
            const xy = this.getCenterPoint(e.target);
            this.knobAngle = this.getAngle(xy, knobXY);
        },
        knobReset: function() {
            var width = window.innerWidth || document.body.clientWidth;
            if (width <= 768) this.knobAngle = -90;
            else this.knobAngle = 0;
        },
        increaseThreatLevel: function() {
            this.threatLevel++;
            // this.knobAngle = -180 + (this.threatLevel * 72) + 72;

            if (this.threatLevel >= 2) {
                this.knobAngle = 70;
            }

            if (this.threatLevel === 1) this.colorAngle = 235;
            if (this.threatLevel === 2) this.colorAngle = 215;
            if (this.threatLevel === 3) this.colorAngle = 175;
        },
        setRandomWiggle: function() {
            const range = 10;
            this.wiggleOffset = Math.floor(Math.random() * range) - (range / 2);
        },
        getAngle: function(c, e) {
            const dy = e.y - c.y;
            const dx = e.x - c.x;
            var theta = Math.atan2(dy, dx);
            theta *= 180 / Math.PI;
            var angle = Math.round(theta - 90);
            if (angle > 180) angle = angle - 360;
            if (angle < -180) angle = angle + 360;
            // console.log(angle);
            return angle;
        },
        getCenterPoint: function(el) {
            const viewportOffset = el.getBoundingClientRect();
            const w = (viewportOffset.right - viewportOffset.left) / 2;
            const h = (viewportOffset.bottom - viewportOffset.top) / 2;
            const x = viewportOffset.left + w;
            const y = viewportOffset.top + h;
            return {x: x, y: y};
        },
        getKnobAngle: function() {
            if (this.wiggleNeedle) {
                return this.knobAngle + this.wiggleOffset;
            }
            return this.knobAngle;
        }
    }
};
