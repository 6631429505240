import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './../node_modules/bulma/css/bulma.css';
import '@/styles.scss';
import WaveSurferVue from 'wavesurfer.js-vue';

Vue.config.productionTip = false;
Vue.use(WaveSurferVue);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
