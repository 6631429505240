<template>
    <modal
        :show="show"
        @close="close">
        <template #headerText>
            About REVA
        </template>

        <template #bodyContent>
            <div class="content">
                <p>
                    <strong>Real-time Enhanced Voice Authentication (REVA)
                        is a new platform capability developed by Eduworks
                        to defend against the financial loss, data compromise,
                        and disinformation campaigns that audio deepfake attacks
                        and voice phishing (vishing) schemes pose. </strong>
                </p>
                <p>
                    The threat of deepfakes and vishing is real and growing.
                    Attacks are becoming more sophisticated, harder to detect,
                    and more ambitious, moving beyond simple criminal fraud to
                    achieving broader economic or political gain and employing
                    advanced algorithms like Generative Adversarial Networks (GANs).
                    This new machine learning technique is being used by bad
                    actors to rapidly develop a synthetic model of an individual’s
                    voice based on minimal voice samples. Such deepfakes are
                    frequently reported in the media because they can be so persuasive.
                    Not surprisingly, nefarious uses of GANs have emerged that
                    include sowing political discord (using seemingly authentic
                    but falsified “recordings” of political leaders), defrauding
                    bank officials, and placing public figures in contrived,
                    compromising circumstances.
                </p>
                <p>
                    REVA employs multiple detection algorithms to analyze calls
                    for signal tampering, voice spoofing, and social engineering
                    threats, so that call recipients can be alerted to the
                    potential threat in real time. Detecting social engineering
                    attacks is  just one application; REVA can be adapted to
                    identify calls fitting other profiles of interest, by
                    training its machine learning models on data from a desired
                    category of conversations. REVA can notify call recipients
                    that an attack may be in progress and can also analyze
                    recorded conversations for larger-scale, non-realtime
                    investigations.
                </p>
                <p>
                    REVA produces accurate results in real time under noisy
                    real-world conditions, gets smarter with use, and can be
                    readily re-trained to incorporate new methods to ward
                    off emerging deepfake attacks. REVA was seed-funded by
                    DARPA, which recognizes the urgency of verifying the
                    authenticity of calls in national security and intelligence
                    community settings. However, its core capabilities also
                    benefit other public and private sector applications by
                    offering a strong layer of security for high-stakes verbal
                    transactions or where the sensitivity of information
                    demands high confidence in authenticity.
                </p>
                <p>
                    For demonstration purposes, REVA is currently available
                    in mobile and web app configurations. An API is in development
                    to enable third party call center platforms, security
                    firms, and voice platform vendors to benefit from REVA’s
                    protection as an out-sourced cloud-based service or
                    integrated on-premise feature.
                </p>
                <p>
                    Eduworks seeks partners to integrate REVA into their
                    platforms to ensure the latest response to emerging
                    threats is always available.
                </p>
            </div>
        </template>

        <template #extraFooterOption>
            <div class="label">
                For more information <a
                    href="mailto:benjamin.bell@eduworks.com?subject=I%20would%20like%20to%20know%20more%20about%20REVA"
                    target="_blank">contact us</a>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/_templates/modal';

export default {
    name: 'ModalAboutReva',
    components: {
        Modal
    },
    data: () => {
        return {
        };
    },
    computed: {
        show: function() {
            return this.$store.getters.isVisible('aboutReva');
        }
    },
    methods: {
        close: function() {
            this.$store.commit('hideModal', 'aboutReva');
        }
    }
};
</script>
