import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // Global values used by components
        mode: 'english',
        // currentAudio: {}, // set when picking or uploading audio
        appModalList: ['', 'aboutReva', 'alert', 'privacyInfo'],
        currentModal: '',
        modalAlertContent: {},
        submitModalTo: null,

        supportedFileTypes: ['mp3', 'wav', 'mp4', 'opus', 'm4a', 'ogg', 'flac', 'mkv', 'webm'],

        // TODO: should this be reset at the end of the analysis?
        tamperingWarningTime: 0, // time to display tampering warning
        displayTamperingMessage: false

        // isMicInput: false // to tell the demo screen if we are running mic input or normal analysis
    },
    getters: {
        // Getters are computed properties for the store state
        // isMicInput: state => {
        //     return state.isMicInput;
        // },
        mode(state) {
            return state.mode;
        },
        audioSamplePath: state => {
            return state.audioSamplePath;
        },
        // currentAudio: state => {
        //     return state.currentAudio;
        // },
        tamperingWarningTime: state => {
            return state.tamperingWarningTime;
        },
        displayTamperingMessage: state => {
            return state.displayTamperingMessage;
        },
        modalAlertContent: state => {
            return state.modalAlertContent;
        },
        getCurrentModal: (state) => {
            return state.currentModal;
        },
        getSubmitModalTo: (state) => {
            return state.submitModalTo;
        },
        isVisible: (state) => (payload) => {
            if (typeof state[payload] === 'undefined') {
                Vue.set(state, payload, false);
            }
            return state[payload];
        }
    },
    mutations: {
        // Mutations are functions that changes the state
        // with a minimum amount of logic, must be synchroneous
        // setIsMicInput(state, val) {
        //     state.isMicInput = val;
        // },
        // setCurrentAudio(state, payload) {
        //     state.currentAudio = payload;
        // },
        setMode(state, value) {
            state.mode = value;
        },
        clearModals: function(state) {
            state.currentModal = '';
            for (let i = 1; i < state.appModalList.length; i++) {
                this.commit('hideModal', state.appModalList[i]);
            }
        },
        changeCurrentModal: function(state, newModal) {
            let i = 0;
            let found = false;
            const valid = state.appModalList;
            this.commit('clearModals');
            for (i = 0; i < valid.length; i++) {
                if (newModal === valid[i]) {
                    state.currentModal = newModal;
                    found = true;
                    this.commit('showModal', newModal);
                    break;
                }
            }
            if (!found) {
                console.log('changeCurrentModal: INVALID MODAL REQUESTED: ' + newModal);
            }
        },
        setModalAlertContent: (state, val) => {
            state.modalAlertContent = val;
        },
        showModal: (state, payload) => {
            state.currentModal = payload;
            Vue.set(state, payload, true);
        },
        hideModal: (state, payload) => {
            state.currentModal = '';
            Vue.set(state, payload, false);
        },
        setSubmitModalTo: (state, payload) => {
            state.submitModalTo = payload;
        },
        setTamperingWarningTime(state, payload) {
            state.tamperingWarningTime = payload;
        },
        setDisplayTamperingMessage(state, payload) {
            state.displayTamperingMessage = payload;
        }
    },
    actions: {
        // Actions set value in state (committing one or more mutations)
        // or set and get values using a rest api
    },
    modules: {}
});
