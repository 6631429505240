<template>
    <div
        id="upload-file"
        class="">
        <div class="columns is-centered">
            <div class="column is-8">
                <div class="content">
                    <h1 class="title has-text-light">
                        {{ text[mode].onlineLink }}
                    </h1>
                    <p>
                        {{ text[mode].pasteURLInstructions }}
                    </p>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label
                            class="label"
                            for="url">{{ text[mode].linke }}</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control has-icons-left">
                                <input
                                    v-model="audioLink"
                                    id="url"
                                    name="url"
                                    class="input"
                                    type="url"
                                    pattern="https://.*"
                                    :placeholder="text[mode].pasteURLHere"
                                    @change="checkAudioLink"
                                    required>
                                <span class="icon is-small is-left">
                                    <i class="fas fa-link" />
                                </span>
                            </div>
                            <p
                                v-if="errorMsg"
                                class="help is-danger">
                                {{ errorMsg }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label is-normal" />
                    <div class="field-body">
                        <div class="control">
                            <button
                                @click="submitAudio"
                                class="button is-primary"
                                :disabled="!audioLink || errorMsg !== ''">
                                {{ text[mode].submit }}
                                <i
                                    v-if="isLoading"
                                    class="fas fa-circle-notch fa-spin" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Use example mp3 from https://file-examples.com/index.php/sample-audio-files/sample-mp3-download/
import revaAPI from '../api/revaAPI.js';

export default {
    name: 'UploadLink',
    components: {},
    data: () => {
        return {
            text: {
                english: {
                    onlineLink: 'Online Link',
                    pasteURLInstructions: 'Paste a URL to any audio files or Youtube video. The audio will be analyzed by REVA and the results will be displayed',
                    link: 'Link',
                    pasteURLHere: 'Paste URL here',
                    submit: 'Submit',
                    fileTooLarge: 'Your file is too large',
                    uploadLimit: 'The upload limit is 10MB',
                    pleaseEnter: 'Please enter a link to an MP3, WAV, MP4, OPUS, M4A, OGG, FLAC file or a link to a Youtube video'
                },
                ukrainian: {
                    onlineLink: 'Онлайн-посилання',
                    pasteURLInstructions: 'Вставте URL-адресу будь-якого аудіофайла або Youtube відео. REVA проаналізує аудіо та відобразить результати',
                    link: 'Посилання',
                    pasteURLHere: 'Вставте URL-адресу тут',
                    submit: 'Відіслати',
                    fileTooLarge: 'Ваш файл занадто великий',
                    uploadLimit: 'Ліміт завантаження - 10 МБ',
                    pleaseEnter: 'Будь ласка, введіть посилання на файл MP3, WAV, MP4, OPUS, M4A, OGG, FLAC або посилання на відео на YouTube'
                }
            },
            audioLink: '',
            errorMsg: '',
            isLoading: false,
            buttonText: '',
            filename: ''
        };
    },
    computed: {
        mode() {
            return this.$store.state.mode;
        },
        supportedFileTypes() {
            return this.$store.state.supportedFileTypes;
        }
    },
    mounted() {
        if (!localStorage.isAuthenticated && this.$route.name !== 'Login') {
            return this.$router.push('/login');
        }
    },
    methods: {
        checkAudioLink: function() {
            if (this.isFileTypeSupported(this.audioLink)) {
                this.errorMsg = '';
            } else {
                this.errorMsg = this.text[this.mode].pleaseEnter;
            }
        },
        isFileTypeSupported(filename) {
            const ext = filename.split('.').pop();
            if (this.supportedFileTypes.includes(ext) || filename.indexOf('youtube.com/') !== -1) {
                return true;
            }
            return false;
        },
        createFileFromUrl: async function(url) {
            const response = await fetch(url);
            const data = await response.blob();
            this.filename = url.substring(url.lastIndexOf('/') + 1);
            const ext = this.filename.split('.').pop();
            let filetype = '';

            if (ext === 'wav') {
                filetype = 'audio/wav';
            } else if (ext === 'ogg') {
                filetype = 'audio/ogg';
            } else if (ext === 'opus') {
                filetype = 'audio/opus';
            } else if (ext === 'm4a' || ext === 'mp4') {
                filetype = 'audio/mp4';
            } else if (ext === 'flac') {
                filetype = 'audio/flac';
            } else {
                filetype = 'audio/mpeg';
            }

            const metadata = {
                type: filetype
            };

            return new File([data], this.filename, metadata);
        },
        handleResponse(res) {
            if (res.data) {
                const uploadSuccessful = res.data.upload_successful;
                if (!uploadSuccessful) {
                    console.log('upload failed');
                    this.isLoading = false;
                    this.buttonText = this.text[this.mode].dropFileHere;

                    this.showModalAlert('alert',
                        {
                            title: this.text[this.mode].fileTooLarge,
                            description: this.text[this.mode].uploadLimit
                        });
                } else {
                    const audio = {
                        title: res.data.filename,
                        display: '',
                        source: 'link',
                        url: res.data.audio_url,
                        filename: this.filename
                    };
                    localStorage.currentAudio = JSON.stringify(audio);
                    localStorage.sampleIsVideo = res.data.is_video;
                    localStorage.isMicInput = false;

                    this.$router.push({name: 'Demo'});
                }
            } else {
                console.log('File upload failed...');
            }
        },
        submitAudio: async function() {
            if (this.audioLink.indexOf('youtube.com/') !== -1) {
                this.isLoading = true;
                this.buttonText = '';

                revaAPI.uploadYoutubeVideo(this.audioLink, (response) => {
                    this.handleResponse(response);
                });
            } else {
                const audioFile = await this.createFileFromUrl(this.audioLink);
                revaAPI.uploadFile(audioFile, (response) => {
                    this.handleResponse(response);
                });
            }
        },
        showModalAlert(id, content) {
            if (id !== '') {
                this.$store.commit('setModalAlertContent', content);
                this.$store.commit('changeCurrentModal', id);
                return this.activeModal === id;
            }
        }
    }
};
</script>
