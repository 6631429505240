<template>
    <div
        :style="styles"
        class="waveLoader">
        <div
            :style="barStyles"
            class="bar bar-1" />
        <div
            :style="barStyles"
            class="bar bar-2" />
        <div
            :style="barStyles"
            class="bar bar-3" />
        <div
            :style="barStyles"
            class="bar bar-4" />
        <div
            :style="barStyles"
            class="bar bar-5" />
    </div>
</template>

<script>
export default {
    props: {
        size: {
            default: '60px'
        },
        duration: {
            default: '1.2s'
        }
    },
    computed: {
        barStyles() {
            return {
                animationDuration: this.duration
            };
        },
        styles() {
            return {
                width: this.size,
                height: this.size
            };
        }
    }
};
</script>

<style>
  .waveLoader {
    display: inline-block;
    /* margin-top: 10%; */
    /* margin-bottom: 10%; */
  }
  .waveLoader > div {
    height: 100%;
    display: inline-block;
    margin-right: 3px;
    box-shadow: 0px 0px 6px #03bafc, inset 0px 0px 6px #03bafc;
    border: 3px solid #03bafc;
    border-radius: 10000px;
  }
  .bar {
    animation: sk-stretchdelay 1.2s ease-in-out infinite;
  }
  .bar-2 {
    animation-delay: -1.1s;
  }
  .bar-3 {
    animation-delay: -1.0s;
  }
  .bar-4 {
    animation-delay: -0.9s;
  }
  .bar-5 {
    animation-delay: -0.8s;
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
    }
  }
</style>
