<template>
    <div id="waveFormWrapper">
        <wavesurfer
            id="waveSurfer"
            ref="player"
            :src="audio"
            :options="options" />

        <!-- These hidden HTML elements get turned into Reva marker icons -->
        <!-- <div class="is-hidden">
            <i
                id="marker-tampering"
                class="wave-marker-icon fa-fw fas fa-microphone" />
        </div> -->
    </div>
</template>

<script>
// import Cursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
// import Markers from 'wavesurfer.js/dist/plugin/wavesurfer.markers';
// import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions';

// See: https://codepen.io/joshikeerti/pen/WNvbxMO
// Use example mp3 from https://file-examples.com/index.php/sample-audio-files/sample-mp3-download/

export default {
    props: {
        file: String
    },
    data() {
        return {
            isPaused: false,
            options: {
                interact: false,
                height: 157,
                responsive: true,
                normalize: true,
                fillParent: true,
                scrollParent: true,
                hideScrollbar: true,
                minPxPerSec: 100,
                mediaControls: false,
                cursorColor: '#fcdb03',
                cursorWidth: 1,
                // backend: 'MediaElement',
                // this option seems to help make sure the waveform
                // is drawn properly before audio starts playing
                backend: 'WebAudio',
                barWidth: 2,
                barMinHeight: 2,
                barRadius: 2,
                barGap: 2,
                pixelRatio: 1,
                partialRender: true,
                progressColor: '#03bafc',
                waveColor: 'rgba(0,0,0,0.15)',
                autoCenter: true,
                // plugins: [
                // Cursor.create(),
                // Markers.create(),
                // Regions.create()
                // ],
                xhr: {
                    method: 'GET',
                    mode: 'cors',
                    // setting to no-cache fixes a rare cors error for certain samples
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    referrer: 'no-referrer'
                }
            }
            // tamperingWarningTriggered: false,
        };
    },
    computed: {
        player() {
            return this.$refs.player.waveSurfer;
        },
        audio() {
            if (this.file.startsWith('http')) {
                return this.file;
            }
            return encodeURI(this.file);
        }
        // tamperingWarningTime() {
        //     return this.$store.state.tamperingWarningTime;
        // },
    },
    watch: {
        isPaused: function(paused) {
            if (paused) this.player.pause();
            else this.player.play();
        }
    },
    created() {
    },
    mounted() {
        // this.player.on('loading', (e) => {
        //     console.log('loading:', e);
        // });
        // this.player.on('play', () => {
        //     console.log('play...');
        // });
        // this.player.on('waveform-ready', () => {
        //     console.log('waveform-ready...');
        // });


        this.player.on('error', (errorMsg) => {
            console.log('ERROR: ' + errorMsg);
        });
        this.player.on('ready', () => {
            // console.log('Player ready');

            // A region is a highlited area in the waveform display
            // this.player.addRegion({
            //     start: 3.5, // time in seconds
            //     end: 4, // time in seconds
            //     color: 'rgba(255, 255, 0, 0.1)'
            // });

            this.ready();
        });
        // this event keeps firing while the audio is playing
        this.player.on('audioprocess', () => {
            // console.log("audioprocess...")

            // TODO: add threat level display handling

            // check if we hit the tamperingTime
            // if (this.tamperingWarningTime !== 0 && this.player.getCurrentTime() >= this.tamperingWarningTime && !this.tamperingWarningTriggered) {
            //     this.addMarker('tampering', this.tamperingWarningTime);
            //     this.playSound('sfx_unrecognized1.mp3');

            //     this.$store.commit('setDisplayTamperingMessage', true);
            //     this.tamperingWarningTriggered = true;
            // }

            // if (this.tamperingWarningTriggered) {
            //     this.player.unAll(); // unregister all events (including this one)
            // }
        });
    },
    updated() {
        // this.player.on('finish', () => {
        //     console.log('Player finish');
        //     this.isPaused = true;

        //     if (this.tamperingWarningTime === 0) {
        //         console.log('tampering time empty');
        //         this.$store.commit('setDisplayTamperingMessage', true);
        //         this.playSound('sfx_trusted1.mp3');
        //     }
        // });
    },
    methods: {
        pause() {
            this.isPaused = true;
        },
        play() {
            this.isPaused = false;
        },
        ready() {
            // tell parent that the audio is ready and will play now
            this.$emit('ready');
            this.player.setMute(true);
            this.player.play();
        },
        addMarker(type, time) {
            var label = '';

            if (type === 'tampering') {
                // var icon = document.getElementById('marker-' + type);
                label = 'Tampering Detected';
            }

            var marker = {
                time: time,
                label: label,
                color: '#fff'
                // position: 'bottom'
                // TODO: Replaced marker icons don't align properly with the time
                // See: https://wavesurfer-js.org/api/typedef/index.html#static-typedef-MarkerParams
                // markerElement: icon
            };
            this.player.addMarker(marker);
        },
        playSound(filename) {
            new Audio(require('@/assets/' + filename)).play();
        }
    }
};
</script>
