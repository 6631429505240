<template>
    <div id="AudioSamples">
        <div class="samples content">
            <h1 class="title has-text-light">
                {{ text[mode].audioSamples }}
            </h1>
            <p>
                {{ text[mode].selectSample }}
            </p>

            <div class="tabs is-centered">
                <ul>
                    <li
                        @click="activeTab = 'NaturalSpeech 2'"
                        :class="{'is-active':activeTab === 'NaturalSpeech 2'}">
                        <a>NaturalSpeech 2</a>
                    </li>
                    <li
                        @click="activeTab = 'VALL-E'"
                        :class="{'is-active':activeTab === 'VALL-E'}">
                        <a>VALL-E</a>
                    </li>
                    <li
                        @click="activeTab = 'ElevenLabs'"
                        :class="{'is-active':activeTab === 'ElevenLabs'}">
                        <a>ElevenLabs</a>
                    </li>
                    <li
                        @click="activeTab = 'SPEAR-TTS'"
                        :class="{'is-active':activeTab === 'SPEAR-TTS'}">
                        <a>SPEAR-TTS</a>
                    </li>
                </ul>
            </div>

            <!-- <div class="field is-grouped is-grouped-multiline is-grouped-centered"> -->
            <div class="field is-grouped is-grouped-multiline is-grouped-centered">
                <div
                    v-for="sample in activeSamples"
                    :key="sample.name"
                    @click="loadAudio(sample)"
                    class="control is-clickable">
                    <div
                        class="tags has-addons">
                        <span class="tag is-light">{{ sample.name }}</span>
                        <span
                            v-if="sample.generator"
                            class="tag"
                            :generator="sample.generator">{{ sample.generator }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import revaAPI from '../api/revaAPI.js';

export default {
    name: 'AudioSamples',
    components: {},
    data: () => {
        return {
            text: {
                english: {
                    audioSamples: 'Audio Samples',
                    selectSample: "Select a sample below to hear the audio and view REVA's analysis."
                // analysis: "Analysis",
                // generatedAudioDetected: "Generated Audio Detected"
                },
                ukrainian: {
                    audioSamples: 'Аудіо зразки',
                    selectSample: 'Виберіть зразок, щоб почути аудіо та переглянути аналіз REVA'
                // analysis: "Аналіз",
                // generatedAudioDetected: "Виявлено синтезований аудіо"
                }
            },
            audioSamples: [
                {name: 'valle_3.wav', generator: 'VALL-E'},
                {name: 'valle_10.wav', generator: 'VALL-E'},
                {name: 'valle_12.wav', generator: 'VALL-E'},
                {name: 'valle_x_1.wav', generator: 'VALL-E'},
                {name: 'valle_x_2.wav', generator: 'VALL-E'},
                {name: '11_biden.mp3', generator: 'ElevenLabs'},
                {name: '11_nick_offerman_2.mp3', generator: 'ElevenLabs'},
                {name: '11_sample.mp3', generator: 'ElevenLabs'},
                {name: 'spear_1.wav', generator: 'SPEAR-TTS'},
                {name: 'spear_2.wav', generator: 'SPEAR-TTS'},
                {name: 'spear_3.wav', generator: 'SPEAR-TTS'},
                {name: 'naturalSpeech_2.wav', generator: 'NaturalSpeech 2'},
                {name: 'naturalSpeech_3.wav', generator: 'NaturalSpeech 2'},
                {name: 'naturalSpeech_4.wav', generator: 'NaturalSpeech 2'},
                {name: 'naturalSpeech_5.wav', generator: 'NaturalSpeech 2'}
            ],
            activeTab: 'NaturalSpeech 2'
        };
    },
    mounted() {
        if (!localStorage.isAuthenticated && this.$route.name !== 'Login') {
            return this.$router.push('/login');
        }
    },
    computed: {
        mode() {
            return this.$store.state.mode;
        },
        activeSamples() {
            const samples = [];

            for (let i = 0; i < this.audioSamples.length; i++) {
                if (this.audioSamples[i].generator === this.activeTab) {
                    samples.push(this.audioSamples[i]);
                }
            }
            return samples;
        },
        columnStyle() {
            if (this.activeSamples.length <= 3) {
                return 'column is-10-desktop is-12-tablet';
            } else if (this.activeSamples.length <= 6) {
                return 'column is-8-desktop is-9-tablet';
            } else {
                return 'column is-6-desktop is-6-tablet';
            }
        }
    },
    methods: {
        // Go to the demo screen and playback the selected audio sample
        loadAudio: function(sample) {
            const filename = sample.name;

            // using a sample file from the list
            revaAPI.getFileUrl(filename, (response) => {
                if (response.data) {
                    const file = {
                        title: filename,
                        display: filename,
                        source: 'fileName',
                        isVideo: false,
                        authenticateVoice: false,
                        filename: filename,
                        url: response.data.audio_url
                    };

                    localStorage.currentAudio = JSON.stringify(file);
                    localStorage.sampleIsVideo = false;
                    localStorage.isMicInput = false;

                    // NOTE: enable to go to the next page with the clicked file
                    this.$router.push({name: 'Demo'});
                } else {
                    console.log('File ' + filename + ' not found on server');
                }
            });
        }
    }
};
</script>
