import axios from 'axios';

// Get the API URL from the environment variables
const URI = process.env.VUE_APP_HTTP_URL;
// const URI = "https://dev.api.reva.eduworks.com/api/v1/";

const REVA_API_KEY = process.env.VUE_APP_REVA_API_KEY;

const ELEVEN_LABS_URI = 'https://api.elevenlabs.io/v1/'
const ELEVEN_LABS_API_KEY = '10eecb3209adf3bdd0e4ad339eacd712'

export default {
    // Get the audio url for a given filename
    getFileUrl(audio, callback) {
        console.log('GET /api/v1/files/' + audio);

        const options = {
            method: 'GET',
            url: URI + 'files/' + audio,
            headers: {token: REVA_API_KEY}
        };

        axios(options).then(function(response) {
            console.log('Response Data:', response.data);
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },
    // Upload a new file and get the file URL
    uploadFile(audio, callback) {
        console.log('POST /api/v1/uploads/', audio);

        var fd = new FormData();
        fd.append('file', audio);

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
                token: REVA_API_KEY
            },
            method: 'POST',
            data: fd,
            url: URI + 'uploads/'
        };
        axios(options).then(function(response) {
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },
    uploadZip(zip, callback) {
        console.log('POST /api/v1/uploads/bulk/analyze', zip);

        var fd = new FormData();
        fd.append('file', zip);

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
                token: REVA_API_KEY
            },
            method: 'POST',
            data: fd,
            url: URI + 'uploads/bulk/analyze'
        };
        axios(options).then(function(response) {
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },

    uploadCsv(csv, callback) {
        console.log('POST /api/v1/links/bulk/analyze', csv);

        var fd = new FormData();
        fd.append('file', csv);

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
                token: REVA_API_KEY
            },
            method: 'POST',
            data: fd,
            url: URI + 'links/bulk/analyze'
        };
        axios(options).then(function(response) {
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },

    // Upload a Youtube video and get the file URL
    uploadYoutubeVideo(url, callback) {
        console.log('POST /api/v1/uploads/youtube/', url);

        const options = {
            headers: {token: REVA_API_KEY},
            method: 'POST',
            url: URI + 'uploads/youtube/' + encodeURIComponent(url)
        };
        axios(options).then(function(response) {
            console.log('Response Data:', response.data);
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },

    // Eleven Labs endpoints
    generateVoice(voice_id, input_text, callback) {
        console.log("POST", ELEVEN_LABS_URI, "/text-to-speech/", voice_id);

        const options = {
            method: 'POST',
            url: ELEVEN_LABS_URI + "text-to-speech/" + voice_id,
            data: {
                text: input_text,
                voice_settings: {
                    stability: .25,
                    similarity_boost: .25
                }
            },
            responseType: 'arraybuffer',
            headers: { 
                'xi-api-key': ELEVEN_LABS_API_KEY, 
                'Content-Type': 'application/json',
            }
        };

        axios(options).then(function(response) {
            console.log('Response Data:', response.data);
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },

    getVoices(callback) {
        console.log("GET", ELEVEN_LABS_URI, "/voices");

        const options = {
            method: 'GET',
            url: ELEVEN_LABS_URI + "voices",
            headers: { 
                'xi-api-key': ELEVEN_LABS_API_KEY, 
                'Content-Type': 'application/json'
            }
        };

        axios(options).then(function(response) {
            console.log('Response Data:', response.data);
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },

    enrollVoice(name, file, callback) {
        console.log("POST", ELEVEN_LABS_URI, "/voices/add");

        let fd = new FormData();
        fd.append('name', name);
        fd.append('files', file);

        const date = Date.now();
        const labels = '{"dateCreated":"' + date +  '"}'
        fd.append('labels', labels);

        const options = {
            method: 'POST',
            url: ELEVEN_LABS_URI + "voices/add",
            data: fd,
            headers: { 
                'xi-api-key': ELEVEN_LABS_API_KEY, 
                'Content-Type': 'application/json',
            }
        };

        axios(options).then(function(response) {
            console.log('Response Data:', response.data);
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },
    deleteVoice(voice_id, callback) {
        console.log("DELETE", ELEVEN_LABS_URI, "/voices/", voice_id);

        const options = {
            method: 'DELETE',
            url: ELEVEN_LABS_URI + "voices/" + voice_id,
            headers: { 
                'xi-api-key': ELEVEN_LABS_API_KEY, 
                'Content-Type': 'application/json',
            }
        };
        axios(options).then(function(response) {
            console.log('Response Data:', response.data);
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },
    detectTampering(file, callback) {
        console.log("POST", URI, "uploads/analyze");

        let fd = new FormData();
        fd.append('file', file);

        const options = {
            method: 'POST',
            url: URI + "uploads/analyze",
            data: fd,
            headers: { 
                'token': REVA_API_KEY
            }
        };

        axios(options).then(function(response) {
            console.log('Response Data:', response.data);
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },
    getVersion(callback) {
        const options = {
            method: 'GET',
            url: URI + 'version',
            headers: {token: REVA_API_KEY}
        };

        axios(options).then(function(response) {
            console.log('Response Data:', response.data);
            callback(response);
        }).catch(function(error) {
            console.log(error);
            callback(error);
        });
    },
    // TODO: add endpoint for deleting a voice clone
};
