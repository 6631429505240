import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Demo from '../views/Demo.vue';
import UploadFile from '../views/UploadFile.vue';
import UploadLink from '../views/UploadLink.vue';
import Login from '../views/Login.vue';
import CloneVoice from '../views/CloneVoice.vue';
import AudioSamples from '../views/AudioSamples';
import VideoSamples from '../views/VideoSamples';


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/file',
        name: 'Upload File',
        component: UploadFile
    },
    {
        path: '/link',
        name: 'Upload Link',
        component: UploadLink
    },
    {
        path: '/clone-voice',
        name: 'Clone Voice',
        component: CloneVoice
    },
    {
        path: '/audio-samples',
        name: 'AudioSamples',
        component: AudioSamples
    },
    {
        path: '/video-samples',
        name: 'VideoSamples',
        component: VideoSamples
    },
    {
        path: '/demo',
        name: 'Demo',
        component: Demo
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
];

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
