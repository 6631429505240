<template>
    <div class="container">
        <div
            class="columns is-centered is-hidden-mobile"
            v-show="recordingSectionActive">
            <div class="column is-8">
                <div class="block">
                    <p class="has-text-centered">
                        REVA is a service that detects synthetic speech. This demo allows users to
                        record their own voice, create a synthetic clone, generate some synthetic speech,
                        and show that REVA can detect it.
                    </p>
                </div>
                <div class="block">
                    <p class="has-text-centered">
                        Click "Start Recording" to create a sample of your voice. Say whatever you want, but try to talk for at least 30 seconds.
                    </p>
                </div>
                <div class="block centered-content">
                    <button
                        v-if="isRecording"
                        id="btn-stop-recording"
                        @click="stopRecording"
                        class="button is-raised pulse">
                        Stop Recording
                    </button>
                    <button
                        v-else
                        id="btn-start-recording"
                        @click="startRecording"
                        class="button is-raised">
                        Start Recording
                    </button>
                </div>
            </div>
        </div>

        <div
            class="columns is-centered is-hidden-mobile"
            v-show="uploadSectionActive">
            <div class="column is-8">
                <div class="block">
                    <p class="has-text-centered">
                        You can playback your recording here:
                    </p>
                </div>
                <div class="block centered-content">
                    <audio
                        class="audio-player"
                        controls
                        playsinline />
                </div>
                <div class="block">
                    <p class="has-text-centered">
                        Click the following button to run detection with your audio file:
                    </p>
                </div>

                <div class="block centered-content">
                    <button
                        v-if="!isLoading"
                        class="button is-raised"
                        @click="detectTampering">
                        Detect Generated Audio
                    </button>
                    <button
                        v-else
                        class="button is-raised is-loading">
                        Detect Generated Audio
                    </button>
                </div>
            </div>
        </div>

        <div
            class="columns is-centered is-hidden-mobile"
            v-show="firstTamperingSectionActive">
            <div class="column is-8">
                <div
                    class="block"
                    v-if="gotTamperingResult">
                    <div
                        v-if="!tamperingDetected"
                        class="notification is-success is-light">
                        <p><strong>No Generated Audio Detected!</strong></p>
                        <p>
                            REVA has determined that this sample contains only genuine speech
                        </p>
                    </div>
                    <div
                        v-else
                        class="notification is-danger is-light">
                        <p><strong>Generated Audio Detected</strong></p>
                        <p>
                            REVA has determined that this sample contains synthetic speech
                        </p>
                    </div>
                </div>

                <div class="block">
                    <p class="has-text-centered">
                        Click "Upload Sample" to create a voice clone using your input sample:
                    </p>
                </div>
                <div
                    v-if="gotTamperingResult"
                    class="block centered-content">
                    <button
                        v-if="!isLoading"
                        class="button is-raised"
                        @click="uploadVoice">
                        Upload Sample
                    </button>
                    <button
                        v-else
                        class="button is-raised is-loading">
                        Upload Sample
                    </button>
                </div>
                <div class="block">
                    <p class="has-text-centered">
                        <span
                            id="privacy-info"
                            class="icon-text"
                            @click="showModal('privacyInfo')">
                            <span id="privacy-info-text">View privacy info</span>
                            <span class="icon">
                                <i class="fas fa-info-circle" />
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <div
            class="columns is-centered is-hidden-mobile"
            v-show="enterTextSectionActive">
            <div class="column is-8">
                <div class="block">
                    <p class="has-text-centered">
                        Your voice clone has been created! Enter some text to hear it back in your voice.
                    </p>
                </div>
                <div class="block">
                    <textarea
                        class="textarea"
                        placeholder="e.g. Hello fellow human!"
                        cols="3"
                        :minlength="minTextLength"
                        :maxlength="maxTextLength"
                        v-model="inputText" />
                    <p id="text-length">
                        {{ inputTextLengthDisplay }}
                    </p>
                    <p
                        id="text-length-warning"
                        v-if="showInputTextWarning">
                        Not enough text. Try to input at least 40 characters
                    </p>
                </div>
                <div class="block">
                    <div class="centered-content">
                        <button
                            v-if="!isLoading"
                            class="button is-raised"
                            @click="createVoice">
                            Generate voice
                        </button>
                        <button
                            v-else
                            class="button is-raised is-loading">
                            Generate voice
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="columns is-centered is-hidden-mobile"
            v-show="tamperingSectionActive">
            <div class="column is-8">
                <div class="block">
                    <p class="has-text-centered">
                        Playback the generated voice here:
                    </p>
                </div>
                <div class="block centered-content">
                    <audio
                        class="audio-player2"
                        controls
                        playsinline />
                </div>

                <div
                    v-if="!gotTamperingResult"
                    class="block">
                    <p class="has-text-centered">
                        Now, click the following button to run detection with your audio file:
                    </p>
                </div>
                <div
                    v-if="!gotTamperingResult"
                    class="block centered-content">
                    <button
                        v-if="!isLoading"
                        class="button is-raised"
                        @click="detectTampering">
                        Detect Generated Audio
                    </button>
                    <button
                        v-else
                        class="button is-raised is-loading">
                        Detect Generated Audio
                    </button>
                </div>

                <div
                    class="block"
                    v-if="gotTamperingResult">
                    <div
                        v-if="!tamperingDetected"
                        class="notification is-success is-light">
                        <p><strong>No Generated Audio Detected!</strong></p>
                        <p>
                            REVA has determined that this sample contains only genuine speech
                        </p>
                    </div>
                    <div
                        v-else
                        class="notification is-danger is-light">
                        <p><strong>Generated Audio Detected</strong></p>
                        <p>
                            REVA has determined that this sample contains synthetic speech
                        </p>
                    </div>

                    <div class="block centered-content">
                        <button
                            class="button is-raised"
                            @click="generateNewSample">
                            Create another sample
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <modal-privacy-info
            id="modalPrivacyInfo"
            :show="isVisible('privacyInfo')"
            :class="{ 'is-active':isVisible('privacyInfo') }"
            @close="closeModal('privacyInfo')" />
    </div>
</template>


<script>
import revaAPI from '../api/revaAPI.js';
import modalPrivacyInfo from '@/components/modalPrivacyInfo.vue';

export default {
    name: 'CloneVoice',
    components: {
        modalPrivacyInfo
    },
    data: () => {
        return {
            isRecording: false,
            stream: null,
            audio: null,
            rec: null,
            microphone: null,
            mediaRecorder: null,
            chunks: [],
            apiKey: '10eecb3209adf3bdd0e4ad339eacd712',
            voiceId: '',
            inputText: '',
            file: null,
            tamperingScores: [],
            minTextLength: 40,
            maxTextLength: 480,

            recordingSectionActive: true,
            uploadSectionActive: false,
            firstTamperingSectionActive: false,
            enterTextSectionActive: false,
            tamperingSectionActive: false,

            showInputTextWarning: false,
            tamperingDetected: false,
            isLoading: false,
            gotTamperingResult: true,
            tamperingThreshold: -1.5
        };
    },
    mounted() {

    },
    beforeMount() {

    },
    computed: {
        inputTextLengthDisplay() {
            return this.inputText.length + '/' + this.maxTextLength;
        }
    },
    methods: {
        isVisible: function(id) {
            if (id !== '') {
                return this.$store.getters.isVisible(id);
            } else {
                return false;
            }
        },
        showModal: function(id) {
            if (id !== '') {
                this.$store.commit('changeCurrentModal', id);
                return this.activeModal === id;
            }
        },
        closeModal: function(id) {
            if (id !== '') {
                // this.$store.commit('clearModals');
                this.$store.commit('hideModal', id);
                return false;
            }
        },
        generateNewSample() {
            this.tamperingScores = [];
            this.inputText = '';
            this.tamperingDetected = false;
            this.tamperingSectionActive = false;
            this.gotTamperingResult = false;
            this.enterTextSectionActive = true;
        },
        async startRecording() {
            const constraints = {audio: true};
            await navigator.mediaDevices.getUserMedia(constraints).then(this.onSuccess, this.onError);

            this.mediaRecorder.start();
            console.log('recorder started');

            this.isRecording = true;
        },
        async stopRecording() {
            this.mediaRecorder.stop();
            console.log('recorder stopped');

            this.isRecording = false;
        },
        onSuccess(stream) {
            this.mediaRecorder = new MediaRecorder(stream);

            this.mediaRecorder.ondataavailable = (e) => {
                this.chunks.push(e.data);
            };

            this.mediaRecorder.onstop = (e) => {
                this.recordingSectionActive = false;
                this.uploadSectionActive = true;

                const audioPlayer = document.querySelector('.audio-player');

                if (audioPlayer) {
                    audioPlayer.setAttribute('controls', '');
                    audioPlayer.controls = true;

                    const blob = new Blob(this.chunks, {type: 'audio/ogg; codecs=opus'});
                    const audioUrl = window.URL.createObjectURL(blob);
                    audioPlayer.src = audioUrl;

                    this.file = new File([blob], 'test.ogg');

                    // release user microphone
                    stream.getTracks().forEach(function(track) {
                        track.stop();
                    });
                }
            };
        },
        onError(err) {
            console.log('The following error occured: ' + err);
        },
        // delete voice if older than 30 min
        shouldDelete(currentTime, timeVoiceCreated) {
            const diff = Math.floor(((currentTime - timeVoiceCreated) / 1000) / 60);
            const voiceLifetime = 30; // minutes

            if (diff >= voiceLifetime) {
                return true;
            } else {
                return false;
            }
        },
        async uploadVoice() {
            const name = 'reva_demo_voice';
            this.isLoading = true;

            // first delete old voices
            this.deleteOldVoices();

            // can these run concurrently?
            revaAPI.enrollVoice(name, this.file, (response) => {
                if (response.data) {
                    console.log('response.data:', response.data);
                    this.voiceId = response.data.voice_id;

                    this.uploadSectionActive = false;
                    this.enterTextSectionActive = true;
                    this.firstTamperingSectionActive = false;
                    this.gotTamperingResult = false;

                    this.tamperingScores = [];
                    this.tamperingDetected = false;
                }
                this.isLoading = false;
            });
        },
        async deleteOldVoices() {
            revaAPI.getVoices((response) => {
                const voices = response.data.voices;
                const currentTime = Date.now();

                for (let i = 0; i < voices.length; i++) {
                    const dateCreated = voices[i].labels.dateCreated;

                    if (dateCreated) {
                        if (this.shouldDelete(currentTime, dateCreated)) {
                            const voiceId = voices[i].voice_id;

                            revaAPI.deleteVoice(voiceId, (response) => {
                                if (response.data) {
                                    console.log('deleting voice...');
                                } else {
                                    console.log('no response data...');
                                }
                            });
                        }
                    }
                }
            });
        },
        async createVoice() {
            if (this.inputText.length < this.minTextLength) {
                this.showInputTextWarning = true;
            } else {
                this.showInputTextWarning = false;
                this.isLoading = true;

                revaAPI.generateVoice(this.voiceId, this.inputText, (response) => {
                    if (response.data) {
                        console.log('response:', response);

                        const audioPlayer = document.querySelector('.audio-player2');

                        if (audioPlayer) {
                            audioPlayer.setAttribute('controls', '');
                            audioPlayer.controls = true;

                            const blob = new Blob([response.data], {type: 'audio/ogg; codecs=opus'});
                            const audioUrl = window.URL.createObjectURL(blob);
                            audioPlayer.src = audioUrl;

                            this.file = new File([blob], 'test.ogg');

                            this.enterTextSectionActive = false;
                            this.tamperingSectionActive = true;


                            // NOTE: deleting on navigate away from page for now
                            // revaAPI.deleteVoice(this.voiceId, (response) => {
                            //     if (response.data) {
                            //         console.log('deleting voice...');
                            //         console.log('response.data:', response.data);
                            //     } else {
                            //         console.log('no response data...');
                            //         console.log('response:', response);
                            //     }
                            // });
                        }
                    }
                    this.isLoading = false;
                });
            }
        },
        async detectTampering() {
            this.isLoading = true;

            revaAPI.detectTampering(this.file, (response) => {
                if (response.data) {
                    this.tamperingScores = response.data.tampering_scores;
                    console.log('tampering scores:', this.tamperingScores);
                    this.gotTamperingResult = true;
                    this.tamperingDetected = this.checkIfTampered(this.tamperingScores);

                    if (this.uploadSectionActive) {
                        console.log('first tampering section');
                        // this.tamperingDetected = false;
                        this.firstTamperingSectionActive = true;
                        this.uploadSectionActive = false;
                    } else {
                        console.log('second tampering section');
                        // this.tamperingDetected = true;
                    }
                }
                this.isLoading = false;
            });
        },
        checkIfTampered(scores) {
            // check if any score in scores is greater than the tampering threshold
            const tampered = scores.some(el => el > this.tamperingThreshold);
            return tampered;
        }
    },
    // beforeDestroy() {
    //     console.log('before destroy...');
    //     window.removeEventListener('beforeunload', this.deleteVoice);
    // },
    // runs when navigating away from the current page
    beforeRouteLeave(to, from, next) {
        console.log('before route leave...');

        if (this.voiceId) {
            console.log('have a voice id...');

            revaAPI.deleteVoice(this.voiceId, (response) => {
                if (response.data) {
                    console.log('deleting voice...');
                    console.log('response.data:', response.data);

                    next();
                } else {
                    console.log('no response data...');
                    console.log('response:', response);
                }
            });
        } else {
            next();
        }

        // if (this.voiceId) {
        //     const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        //     if (answer) {
        //         next();
        //     } else {
        //         return false;
        //     }
        // } else {
        //     next();
        // }
    }
};
</script>
