<template>
    <div>
        <div class="columns is-centered is-hidden-mobile">
            <div
                class="column"
                style="width: 35rem;">
                <p class="has-text-centered effect-fade-up">
                    {{ text[mode].headline }}
                </p>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="knob-labels labels-left">
                    <legend>{{ text[mode].selectSample }}</legend>
                    <router-link
                        :to="{name: 'AudioSamples'}"
                        class="label"
                        @mouseenter.native="knobTurnTo"
                        @mouseleave.native="knobReset">
                        <span class="led" />{{ text[mode].audioSamples }}
                    </router-link>
                    <router-link
                        :to="{name: 'VideoSamples'}"
                        class="label"
                        @mouseenter.native="knobTurnTo"
                        @mouseleave.native="knobReset">
                        <span class="led" />{{ text[mode].videoSamples }}
                    </router-link>
                </div>
            </div>
            <div class="column">
                <div id="knob">
                    <img
                        class="knob-static"
                        src="@/assets/knob-static.svg">
                    <img
                        class="knob-rotate"
                        :style="{'transform': 'rotate('+knobAngle+'deg)'}"
                        src="@/assets/knob-rotate.svg">
                </div>
            </div>
            <div class="column">
                <div class="knob-labels labels-right">
                    <legend>{{ text[mode].analyzeOtherAudio }}</legend>
                    <!-- Disabling mic input for now -->
                    <!-- <a
                        class="label"
                        @click="analyzeMicInput"
                        @mouseenter="knobTurnTo"
                        @mouseleave="knobReset">
                        <span class="led" />Microphone Input
                    </a> -->
                    <router-link
                        :to="{name: 'Upload File'}"
                        class="label"
                        @mouseenter.native="knobTurnTo"
                        @mouseleave.native="knobReset">
                        <span class="led" />{{ text[mode].upload }}
                    </router-link>
                    <router-link
                        :to="{name: 'Upload Link'}"
                        class="label"
                        @mouseenter.native="knobTurnTo"
                        @mouseleave.native="knobReset">
                        <span class="led" />{{ text[mode].link }}
                    </router-link>

                    <!-- kt (12/20/23): removing for now -->
                    <!-- <router-link
                        :to="{name: 'Clone Voice'}"
                        class="label"
                        @mouseenter.native="knobTurnTo"
                        @mouseleave.native="knobReset">
                        <span class="led" />Clone Voice
                    </router-link> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {knobMixin} from '../mixins/knobMixin.js';
import revaAPI from '../api/revaAPI.js';

export default {
    name: 'Home',
    data: () => {
        return {
            text: {
                english: {
                    headline: 'REVA is a suite of AI-based technologies that verifies caller identity, detects possible deepfakes, and protects against fraud.',
                    selectSample: 'Select A Sample',
                    audioSamples: 'Audio Samples',
                    videoSamples: 'Video Samples',
                    analyzeOtherAudio: 'Analyze Other Audio',
                    upload: 'Upload',
                    link: 'Link'
                },
                ukrainian: {
                    headline: 'REVA — це набір технологій на основі штучного інтелекту, який перевіряє особу абонента, виявляє можливі діпфейки та захищає від шахрайства.',
                    selectSample: 'Виберіть зразок',
                    audioSamples: 'Аудіо зразки',
                    videoSamples: 'Відео зразки',
                    analyzeOtherAudio: 'Аналізуйте інше аудіо',
                    upload: 'Завантаження',
                    link: 'Посилання'
                }
            }
        };
    },
    mixins: [knobMixin],
    components: {
    },
    computed: {
        mode() {
            return this.$store.state.mode;
        },
        appVersion() {
            return process.env.VUE_APP_VERSION;
        }
    },
    mounted() {
        // TODO: handle with auth guard in router (instead of in each component)
        if (!localStorage.isAuthenticated && this.$route.name !== 'Login') {
            return this.$router.push('/login');
        }

        console.log('REVA Web Demo App Version:', this.appVersion);
        this.knobReset();
    },
    created() {
        window.addEventListener('resize', this.knobReset);
    },
    destroyed() {
        window.removeEventListener('resize', this.knobReset);
    },
    methods: {
        analyzeMicInput: function() {
            localStorage.isMicInput = true;
            this.$router.push({name: 'Demo'});
        },
        // Go to the demo screen and playback the selected audio sample
        loadAudio: function(sample) {
            if (sample !== '') {
                // using a sample file from the list
                revaAPI.getFileUrl(sample.title, (response) => {
                    if (response.data) {
                        sample.url = response.data.audio_url;
                        localStorage.currentAudio = JSON.stringify(sample);
                        localStorage.sampleIsVideo = sample.isVideo;
                        localStorage.isMicInput = false;

                        this.$router.push({name: 'Demo'});
                    } else {
                        console.log('File ' + sample.title + ' not found on server');
                    }
                });
            }
        }
    }
};
</script>
